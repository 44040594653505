import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener("turbolinks:load", this.trackPageview)
  }

  disconnect() {
    document.removeEventListener("turbolinks:load", this.trackPageview)
  }

  trackPageview() {
    ga('send', 'pageview', window.location.pathname)
  }
  
  trackDealerRequest() {
    console.log("DEALER EVENT")
    // ga('send', {
    //   hitType: 'event',
    //   eventCategory: 'Dealeraanvragen',
    //   eventAction: 'ontvangen'
    // })
  }

}